import React from 'react';

import type { AppState } from 'types/app';
import LandingSlot from 'containers/LandingSlot.jsx';
import { shouldLazyLoad } from 'helpers/LandingPageUtils';
import { groupBy } from 'helpers/lodashReplacement';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/containers/layoutTupac.scss';

const HEADER = 'header';
const CONTENT = 'content';
const SIDE_CONTENT = 'sideContent';
const HEADER_2 = 'header2';
const CONTENT_2 = 'content2';
const SIDE_CONTENT_2 = 'sideContent2';

const slotLayoutGrouping: Record<string, string> = {
  dividerPrimary: HEADER,
  primary: CONTENT,
  secondary: SIDE_CONTENT,
  dividerSecondary: HEADER_2,
  bottomSecondary: SIDE_CONTENT_2,
  bottomPrimary: CONTENT_2,
  fullPrimary: CONTENT,
  fullSecondary: SIDE_CONTENT
};

interface ILayoutTupacProps {
  slotOrder: string[];
  pageName: string;
  slotData: AppState['landingPage']['pageInfo']['slotData'];
  onComponentClick: () => {};
  ipStatus: string;
  slotContentTypesList: string[];
  heartingAwareComponents: string[];
  heartsData: string[];
  isFullWidth: boolean;
}

const LayoutTupac = (props: ILayoutTupacProps) => {
  const { slotOrder, slotData, heartingAwareComponents, heartsData, ...landingSlotProps } = props;
  const { testId } = useMartyContext();

  const createLandingSlot = (slotName: string, slotIndex: number) => {
    const compName = slotData[slotName] as { componentName?: string }; // casted with keys that this component cares about
    return (
      <LandingSlot
        key={slotName}
        slotName={slotName}
        slotIndex={slotIndex}
        data={slotData[slotName]}
        slotHeartsData={compName && compName.componentName && heartingAwareComponents.includes(compName.componentName) && heartsData}
        shouldLazyLoad={shouldLazyLoad(slotIndex)}
        {...landingSlotProps}
      />
    );
  };

  const slots: Record<string, any> = groupBy(slotOrder, slotName => slotLayoutGrouping[slotName.split('-')[0] as string] as string);

  return (
    <div data-test-id={testId('layoutTupac')} className={css.tupacGrid}>
      <div data-test-id={testId('tupacHeader')} className={css.tupacHeader}>
        {slots[HEADER]?.map(createLandingSlot)}
      </div>
      <div data-test-id={testId('tupacSideContent')} className={css.tupacMenu}>
        {slots[SIDE_CONTENT]?.map(createLandingSlot)}
      </div>
      <div data-test-id={testId('tupacContent')} className={css.tupacMain}>
        {slots[CONTENT]?.map(createLandingSlot)}
      </div>
      <div data-test-id={testId('tupacHeader2')} className={css.tupacHeader2}>
        {slots[HEADER_2]?.map(createLandingSlot)}
      </div>
      <div data-test-id={testId('tupacSideContent2')} className={css.tupacMenu2}>
        {slots[SIDE_CONTENT_2]?.map(createLandingSlot)}
      </div>
      <div data-test-id={testId('tupacContent2')} className={css.tupacMain2}>
        {slots[CONTENT_2]?.map(createLandingSlot)}
      </div>
    </div>
  );
};

export default LayoutTupac;
